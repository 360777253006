import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Link, graphql } from 'gatsby'
import SectionHead from './SectionHead'
import ExhibitionDates from './ExhibitionDates'
import Reveal from 'react-reveal/Reveal';

export default class ExhibitionsPage extends React.Component {
  checkDate(startDateInput, endDateInput) {
    var today = new Date();
    var startDate = new Date(startDateInput);
    var endDate = new Date(endDateInput);
    if (today > startDate && today < endDate ){
      this.conclusion = 'current';
    } else if (today > startDate && today > endDate ) {
      this.conclusion = 'past';
    } else {
      this.conclusion = 'future';
    }
    return this.conclusion;
  }

  render() {
    const { posts, title, range, link, url } = this.props

    return (
      <section className="exhibitions exhibitions--grid">
        <SectionHead title={title} link={link} url={url} />
        <div className="container">
          <div className="columns is-multiline grid">
          {posts.map(({ node: post }) => (
            this.checkDate(post.acf.start_date, post.acf.end_date) === range &&
            <div className="grid__item column is-6-tablet is-4-desktop" key={post.id}>
              <Reveal effect="fadeInUp">
                  <div className="image column is-12">
                    { post.acf.image ?
                      <Img 
                        fluid={post.acf.image.localFile.childImageSharp.fluid} 
                        key={post.acf.image.id}/> 
                    : false }
                  </div>
                  <div className="column is-12">
                    <Link to={ "/exhibitions/" + post.slug}>
                      <h2 style={{marginBottom: '1em', marginTop: '0'}} dangerouslySetInnerHTML={{ __html: post.title }}/>
                    </Link>
                    <ExhibitionDates start={post.acf.start_date} end={post.acf.end_date} location={post.acf.location} />
                    <Link to={ "/exhibitions/" + post.slug}>
                        Read more
                    </Link>
                  </div>
              </Reveal>
            </div>
          ))}
          </div>
        </div>
      </section>
    )
  }
}

ExhibitionsPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  range: PropTypes.string,
  link: PropTypes.string,
  url: PropTypes.string,
  full: PropTypes.string
}

export const pageQuery = graphql`
  fragment ExhibitionListFieldsPast on wordpress__wp_exhibitions {
    id
    title
    excerpt
    date(formatString: "MMMM DD, YYYY")
    slug
    acf {
      start_date(formatString: "MMMM DD, YYYY")
      end_date(formatString: "MMMM DD, YYYY")
      image {
        id
        localFile {
          childImageSharp {
            # Try editing the "maxWidth" value to generate resized images.
            fluid(maxWidth: 1680) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
